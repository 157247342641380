import React, { Component } from 'react';
import Footer from './Footer';
import $ from 'jquery';
import config from './config';


class Project extends Component {

    constructor(){
        super() 
          this.state = {
            data: [],
            archivedata:[]
          }
        
      }

      componentDidMount() {
        document.title = "Projects | India National Youth Orchestra";  
        document.getElementsByTagName("META")[2].content="India Youth Orchestra - Project Gallery";
        document.getElementsByTagName("META")[3].content="India Youth Orchestra, Project Gallery";     
    
        
        
        $.ajax({
        url: config.get('apiUrl')+"/api/album",
        type: "GET",
        dataType: 'json',
        ContentType: 'application/json',
        success: function(data) {
           // console.log(data);
            this.setState({data: data});
        }.bind(this),
        error: function(jqXHR) {
            console.log(jqXHR);
        }.bind(this)
    })


    $.ajax({
      url: config.get('apiUrl')+"/api/albumarchive",
      type: "GET",
      dataType: 'json',
      ContentType: 'application/json',
      success: function(data) {
         // console.log(data);
          this.setState({archivedata: data});
      }.bind(this),
      error: function(jqXHR) {
          console.log(jqXHR);
      }.bind(this)
  })




    // $("loader").fadeOut();
    // $("#preloader").delay(1000).fadeOut("slow");

    } 


    render() {
   
    $(window).scroll(function(){

        //this.alert('ddfdf');
        var sticky = $('header'),
            scroll = $(window).scrollTop();
        
        if (scroll >= 154) sticky.addClass('fixed');
        else sticky.removeClass('fixed');
        
        });
 
        var i=0;
        var j=0;
    return (
      <div id="wrapper">
 


    <div id="banner" className="contac">
<div className="heading2">Projects</div>

</div>

<div id="wrap">
    <div id="contenor">

    {
   
   this.state.data.map(function(item, keyyear) {
        var albumData=  item.year;  
        var albumDataArr = item[albumData]; 

               return (
                <div>   
                                 
                <h1 className="yrheading">{albumData} </h1>
              

                <ul className="album_list" key = {keyyear}> 
{     
albumDataArr.map(function(item1, key1) {
  i=i+1;
  const galleryD = "gallery"+i; 
return (
    //   <h1>{item1.albumname}</h1>
    <li>
    <div className="album_box">
      <div className="item">
        <a data-fancybox={galleryD} href={item1.albumthumbimage} data-caption={item1.imagetitle} >
        <div className="imagBox">
              <img src={item1.albumthumbimage} /></div>
        <label>{item1.albumname}</label>
        <div className="desc"  dangerouslySetInnerHTML={{__html: item1.shortDesc}}></div>
        </a>
      </div>
{ 
    item1.photogallery.map(function(item2, key2) {
    return (
          <div className="item">
            <a data-fancybox={galleryD} href={item2.photothumbimage}  data-caption={item2.phototitle}>
                 <div className="imagBox"><img src={item2.photothumbimage} /></div>
            </a>
          </div>
    );
    })  }      
    
    </div>
  </li> 

);
})}




  </ul> </div>
                
                )
             
             })
               
// archive section             
} 
<div class="heading1">OUR ARCHIVES
  <div class="h_bottom_line"></div>
</div> 
{
this.state.archivedata.map(function(itemarch, keyyeararch) {
  var albumDataarch=  itemarch.year;  
  var albumDataArrarch = itemarch[albumDataarch]; 

         return (
          <div>   
                           
          <h1 className="yrheading">{albumDataarch} </h1>
        

          <ul className="album_list" key = {keyyeararch}> 
{     
  albumDataArrarch.map(function(item1arch, key1arch) {
i=i+1;
const galleryDArch = "gallery"+i; 
return (
//   <h1>{item1.albumname}</h1>
<li>
<div className="album_box">
<div className="item">
  <a data-fancybox={galleryDArch} href={item1arch.albumthumbimage} data-caption={item1arch.imagetitle} >
  <div className="imagBox">
        <img src={item1arch.albumthumbimage} /></div>
  <label>{item1arch.albumname}</label>
  <div className="desc"  dangerouslySetInnerHTML={{__html: item1arch.shortDesc}}></div>
  </a>
</div>
{ 
  item1arch.photogallery.map(function(itemarch2, keyarch2) {
return (
    <div className="item">
      <a data-fancybox={galleryDArch} href={itemarch2.photothumbimage}  data-caption={itemarch2.phototitle}>
           <div className="imagBox"><img src={itemarch2.photothumbimage} /></div>
      </a>
    </div>
);
})  }      

</div>
</li> 

);
})}




</ul> </div>
          
          )
       
       })

             
             }
                



         
        
    </div>
</div>


    <Footer />


      </div>
    );

    

  }
}

export default Project;
