import React, { Component } from 'react';
import Footer from './Footer';
import $ from 'jquery';
import config from './config';

class Artistdirector extends Component {
  constructor(){
    super() 
      this.state = {
        pagedata:[]
      }
  }

  componentDidMount() {
    document.title = "Artist Director | VIJAY UPADHYAYA : CONDUCTOR";  
    document.getElementsByTagName("META")[2].content="India Youth Orchestra - Vijay Upadhyaya was born in Lucknow in India. After obtaining his Bachelor of Arts in English literature, economics and history, he continued his studies at the University of Music and Performing Arts in Graz, Austria where he studied conducting, composition and opera";
    document.getElementsByTagName("META")[3].content="India Youth Orchestra, VIJAY UPADHYAYA : CONDUCTOR";     
   
    
$.ajax({
  url: config.get('apiUrl')+"/api/getpagedetail?pageId=6",
  type: "GET",
  dataType: 'json',
  ContentType: 'application/json',
  success: function(pagedata) {
     // console.log(data);
      this.setState({pagedata: pagedata});
  }.bind(this),
  error: function(jqXHR) {
      console.log(jqXHR);
  }.bind(this)
})


} 

  render() {
   
    $(window).scroll(function(){

        //this.alert('ddfdf');
        var sticky = $('header'),
            scroll = $(window).scrollTop();
        
        if (scroll >= 154) sticky.addClass('fixed');
        else sticky.removeClass('fixed');
        
        });
 
        

    return (
      <div id="wrapper">
    


    {
this.state.pagedata.map(function(item, key) {
  return (  
 <div>
<div id="banner" className="contac">
<div className="heading2">{item.pageHeadingText}</div>

</div>

<div id="wrap">

<div id="contenor">
	<div className="contentf2 managers" dangerouslySetInnerHTML={{__html: item.pageDesc}}>
    
   	   </div>
</div>
</div>
</div>
);
})  } 




    <Footer />


      </div>
    );

    

  }
}

export default Artistdirector;
