import React, { Component } from 'react';
import Footer from './Footer';
import $ from 'jquery';
import config from './config';
//import config from 'react-global-configuration';

class About extends Component {

  constructor(){
    super() 
      this.state = {
        pagedata:[]
      }
  }

  componentDidMount() {
    document.title = "About Us - India National Youth Orchestra";  
    document.getElementsByTagName("META")[2].content="The India Youth Orchestra was created to give young Indian musicians the chance of developing their talents and being exposed to western orchestral repertoire.";
    document.getElementsByTagName("META")[3].content="India Youth Orchestra, Sonia Khan, Vijay Upadhyaya, Orchestra";     
   
    $("loader").fadeOut();
    $("#preloader").delay(1000).fadeOut("slow");
  
    
$.ajax({
  url: config.get('apiUrl')+"/api/getpagedetail?pageId=5",
  type: "GET",
  dataType: 'json',
  ContentType: 'application/json',
  success: function(pagedata) {
     // console.log(data);
      this.setState({pagedata: pagedata});
  }.bind(this),
  error: function(jqXHR) {
      console.log(jqXHR);
  }.bind(this)
})


} 
  

  render() {
    return (
      <div id="wrapper">
    
    {
this.state.pagedata.map(function(item, key) {
  return (  
        <div>

    <div id="banner">
     <img src={item.image} className="desktopimg" />
      <img src={item.bannerImageMobile} className="mobileimg" />
      </div>

 <div id="contenor">
<div className="heading1">{item.pageHeadingText} <div className="h_bottom_line"></div>

</div>

	<div className="contentf2" dangerouslySetInnerHTML={{__html: item.pageDesc}}>
  
    
    	   </div>

<div className="about_videos">
<ul className="video_list">
    <li><a className="videoItem" data-fancybox="iframe"  href={config.get('apiUrl')+"/videos/VIDEO-2018-10-19-15-44-12.mp4"}> <img src={config.get('apiUrl')+"/videos/img01.jpg" } />
    </a>
 
    </li><li><a className="videoItem" data-fancybox="iframe"  href={config.get('apiUrl')+"/videos/VIDEO-2018-10-19-15-44-13.mp4"}> <img src={config.get('apiUrl')+"/videos/img02.jpg" } />
    </a></li>
    
    <li><a className="videoItem" data-fancybox="iframe"  href={config.get('apiUrl')+"/videos/VIDEO-2018-10-19-15-57-19.mp4" } > <img src={config.get('apiUrl')+"/videos/img03.jpg" } />
    </a></li> 
  </ul>
</div>


</div>       
        
        
        </div>

);
})  } 






    <Footer />
      </div>
    );
  }
}

export default About;
