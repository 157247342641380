import React, { Component } from 'react';
import Footer from './Footer';
import $ from 'jquery';
import config from './config';

class Partner extends Component {

    constructor(){
        super() 
          this.state = {
            data: []
          }
        
      }

      componentDidMount() {

        document.title = "Partner";

        $.ajax({
        url: config.get('apiUrl')+"/api/partners",
        type: "GET",
        dataType: 'json',
        ContentType: 'application/json',
        success: function(data) {
           // console.log(data);
            this.setState({data: data});
        }.bind(this),
        error: function(jqXHR) {
            console.log(jqXHR);
        }.bind(this)
    })
    } 

// albumdetail = ()=> {
     
// }

    render() {
   
    $(window).scroll(function(){

        //this.alert('ddfdf');
        var sticky = $('header'),
            scroll = $(window).scrollTop();
        
        if (scroll >= 154) sticky.addClass('fixed');
        else sticky.removeClass('fixed');
        
        });
 

    return (
      <div id="wrapper">
    
    <div id="banner" className="contac">
<div className="heading2">INYOC Partners</div>

</div>

<div id="wrap" className="partners">
    <div id="contenor">
    <div class="contentf2">
    <ul class="partntr_list">

    {
this.state.data.map(function(item, key) {

    return (
   
    <li><a href={item.partnerUrl} target="_blank"> <img src={item.partnerimg} alt={item.title} /></a></li>

     );
    })  } 

  </ul>
	</div>


    </div>
</div>


    <Footer />


      </div>
    );

    
  }
}

export default Partner;
