import React, { Component } from 'react';
import Footer from './Footer';
import $ from 'jquery';
import Recaptcha from 'react-recaptcha';
import config from './config';


class Contact extends Component {

    constructor(props){
        super(props);
         this.state = {
           isDisabled:true,
           isVerified: false
         }                                                                                                 
         this.submitForm = this.submitForm.bind(this);
         this.recaptchaLoaded = this.recaptchaLoaded.bind(this);
         this.verifyCallback = this.verifyCallback.bind(this);
      }

     
      recaptchaLoaded() {
        console.log('capcha successfully loaded');
    }

    verifyCallback(response) {
      if (response) {
        this.setState({
          isVerified:true
        })
        
        $('#captchaeerror').text('');
        console.log('success');
      }
  }

    

      // onChange(value) {
      //   console.log("Captcha value:", value);
      //   this.isValidationCheck();
        // this.setState({
        //   isCaptchaValid:true
        // })

        //$('#captchError').val('1');
        

        // if($('#captchError').val()=='1' && $('#frmError').val()=='1')  
        // {
        //   $('.post_cmnt').removeAttr('disabled');
        // }  


        // this.setState({
        //  // isCaptchaValid:true
        //  isDisabled:false
        // })
      

      hidepoup = () =>
      {
        window.location.href = '/Contact'; 
        //browserHistory.push('/Contact');
        //this.props.history.push('/Contact');
        //this.context.location.transitionTo('/Contact');
      }
      
    
      hideerror (errorId) {
       // console.log(errorId);
        $('#'+errorId).text('');
      } 


      componentDidMount() {
        document.title = "Contact Us -India Youth Orchestra";  
        document.getElementsByTagName("META")[2].content="India Youth Orchestra- Contact Us";
        document.getElementsByTagName("META")[3].content="India Youth Orchestra,Contact Us, Enquiry ";     
     
      }

      validateEmail(email){
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,30}[\.][a-z]{2,5}/g;
        const result = pattern.test(email);
        if(result===true){
            return true;
        } else{
          return false;
        }
      }      

      validatephone(phone)
      {

        const phonenoReg = /^\d{10}$/;
        //const phonenumber=e.target.value;

        const result = phonenoReg.test(phone);
         if(result===true)
         {
             return true;
         } else 
         {
           return false;
         }


      }
/*
      handleChange(e){
        const target = e.target;
          
        if(e.target.name==='contactname'){
          if(e.target.value==='' || e.target.value===null ){
            this.setState({
              contactnameError:true
            })
          } else {
            this.setState({
                contactnameError:false,     
               contactname:e.target.value
            })
          }
        }
  
        if(e.target.name==='phone'){
            this.validatephone(e.target.value);
          }   

        
        if(e.target.name==='email'){
         this.validateEmail(e.target.value);
        }
        
        if(e.target.name==='country'){

            this.setState({   
              country:e.target.value
          })
           //country:e.target.value
        }

        if(e.target.name==='message'){

          this.setState({   
            message:e.target.value
        })
         //country:e.target.value
      }

       if(this.state.contactnameError===false && this.state.emailError===false && this.state.phoneError===false  )
       {
          this.setState({
              isfrmValid:true
              })
              this.isValidationCheck();
        
      
       }
      }      
*/

submitForm(e){
    e.preventDefault();
    console.log('clicked');
var error = false;  
var contactname=$('#contactname').val();   
if(contactname=='')
{
  $('#contacterr').text('Please enter your name');
   error=true;
}

var email=$('#email').val();
if(email=='')
{
  $('#emailerror').text('Please enter email');
   error=true;
}

if(email!='')
{
  if(!this.validateEmail(email))
  {
    $('#emailerror').text('Please enter valid email');
    error=true; 
  }

}

var phone =$('#phone').val();
if(phone=='')
{
  $('#phoneerror').text('Please enter your phone');
   error=true;
}

if(phone!='')
{
    if(!this.validatephone(phone))
    {
      $('#phoneerror').text('Please enter 10 digit number');
      error=true;
    }
}


var country =$('#country').val();
var message =$('#contactmessage').val();

if (this.state.isVerified===false) {
  $('#captchaeerror').text('Please verify that you are a human!');
  error=true;
}

if(error==true)
{
    return false;
} else 
{
  
 const data = {
     contactname: contactname,
     email: email,
     phone: phone,
     country: country,
     message: message

    }

   // data.append("myjsonkey", JSON.stringify(payload));
const datan = JSON.stringify(data);
   console.log(datan);
   $('#messagePoup').show();
    fetch(config.get('apiUrl')+'/api/contactform', {
        method: 'POST',
        headers: {
        //'Accept': 'application/json'
        'Accept': 'application/json'
    },
    body: datan
  })
    .then(function(response) {
       console.log('response '+response);
       
        //return response.json()
      }).then(function(body) {
        //console.log(body);
        console.log('body '+body);
        
      });


    }      
   }



  render() {
   
    $(window).scroll(function(){

        //this.alert('ddfdf');
        var sticky = $('header'),
            scroll = $(window).scrollTop();
        
        if (scroll >= 154) sticky.addClass('fixed');
        else sticky.removeClass('fixed');
        
        });
 
        

    return (
      
 <div id="wrapper">

<div id="banner" className="contac">
    <div className="heading2">Contact us</div>
  </div>  

 <div id="wrap"> 
<div id="contenor">

<div className="contentf2 srollFix">

<div className="contct_form">
<h2>Send us a message</h2>
<div style={{fontSize: '14px',marginBottom: '20px'}}>Your email address will not be published. Required fields are marked <span className="requared">*</span></div>

<div className="right_frm"> 
      <form role="form" method="post">
        <div className="form_grp">
          <div className="form_element">
            <label htmlFor="contactname">Name <span className="requared">*</span></label>
            <input name="contactname" id="contactname" required="" type="text" onKeyUp={() =>this.hideerror('contacterr')}     />
                 <div className="contacterr" id="contacterr"></div>

          </div>
          <div className="form_element">
            <label htmlFor="email">Email <span className="requared">*</span></label>
            <input name="email" id="email" required="" type="email" onKeyUp={() =>this.hideerror('emailerror')} />
            <div className="contacterr" id="emailerror"> </div>
          </div>
        </div>
        <div className="form_grp">
          <div className="form_element">
            <label htmlfor="phone">Phone <span className="requared">*</span></label>
            <input name="phone" id="phone" required=""  min="10" type="tel"  onKeyUp={() =>this.hideerror('phoneerror')} />
            <div className="contacterr" id="phoneerror">  </div>
          </div>
          <div className="form_element">
            <label>Country</label>
            <input name="country" required="" id="country" type="country" />
            <div className="contacterr" id="countryerror" >  </div>
          </div>
        </div>
        <div className="form_grp">
          <div className="form_element email">
            <label>Message</label>
            <textarea rows="8" placeholder="Message" required="" id="contactmessage" name="message" ></textarea>
             <span className="error-message" id="message-error"></span>
          </div>
        </div>

      <div className="form_grp">
         
      <label className="desktopimg">&nbsp;</label>  
  <Recaptcha
            sitekey="6LcHfHUUAAAAAFgb3VP12RD9UrQPQV97kZz5AS63"
            render="explicit"
            onloadCallback={this.recaptchaLoaded}
            verifyCallback={this.verifyCallback}
/>
               <span className="contacterr" id="captchaeerror"></span>
       
        </div>


        <div className="form_grp">

         

         <button type="submit" value="submit"  className="post_cmnt"  onClick= {this.submitForm} >Post Message</button>
        </div>
      </form>
    </div>


 <div className="left_frm">
      <p>For any queries / information  please contact us at : 
<span style={{color: '#0000ff'}}>
          <a title="indianationalyouthorchestra@gmail.com" href="mailto:indianationalyouthorchestra@gmail.com" target="_top"> indianationalyouthorchestra@gmail.com</a>
        </span></p> 
    </div>
  </div>
	</div>
</div>

  <div id="messagePoup" style={{display:'none'}} > 

  <div className="popinner">
  <a className="close" onClick={this.hidepoup}><svg viewPort="0 0 12 12" version="1.1"
     xmlns="http://www.w3.org/2000/svg">
    <line x1="1" y1="11" 
          x2="11" y2="1" 
          stroke="black" 
          stroke-width="2"/>
    <line x1="1" y1="1" 
          x2="11" y2="11" 
          stroke="black" 
          stroke-width="2"/>
</svg>
</a>
<h4>THANK YOU.</h4>
<p style={{textAlign:'center'}} >Your message has been successfully submitted. <br />
  We will get back to you shortly. </p>

  </div>

</div>

</div>








    <Footer />


      </div>
    );

    

  }
}

export default Contact;
