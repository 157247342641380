import React, { Component } from 'react';
import Footer from './Footer';
import $ from 'jquery';
import config from './config';

class Videos extends Component {

    constructor(){
        super() 
          this.state = {
            data: []
          }
        
      }

      componentDidMount() {

        document.title = "Videos -India Youth Orchestra";  
        document.getElementsByTagName("META")[2].content="India Youth Orchestra- Video";
        document.getElementsByTagName("META")[3].content="India Youth Orchestra,Video";     
     

        $.ajax({
        url: config.get('apiUrl')+"/api/video",
        type: "GET",
        dataType: 'json',
        ContentType: 'application/json',
        success: function(data) {
           // console.log(data);
            this.setState({data: data});
        }.bind(this),
        error: function(jqXHR) {
            console.log(jqXHR);
        }.bind(this)
    })
    } 

// albumdetail = ()=> {
     
// }

    render() {
   
    $(window).scroll(function(){

        //this.alert('ddfdf');
        var sticky = $('header'),
            scroll = $(window).scrollTop();
        
        if (scroll >= 154) sticky.addClass('fixed');
        else sticky.removeClass('fixed');
        
        });
 

    return (
      <div id="wrapper">
    
    <div id="banner" className="contac">
<div className="heading2">Videos</div>

</div>

<div id="wrap">
    <div id="contenor">
    <div class="contentf2">
  <ul class="video_list">

    {
this.state.data.map(function(item, key) {

    return (
    <li><a data-fancybox="video" className="videoItem" title={item.title} href={item.videoURLs}> <img src={item.videocoverimg} />
    </a>
    </li>
     );
    })  } 

  </ul>
	</div>


    </div>
</div>


    <Footer />


      </div>
    );

    
  }
}

export default Videos;
