import React  from 'react'
import {
  BrowserRouter as Router,
  Route,
  Link
} from 'react-router-dom'

import Home from './Home';
import About from './About';
import Artistdirector from './Artistdirector';
import Managingdirector from './Managingdirector';
import Project from './Project';
import Videos from './Videos';
import Partner from './Partner';
import Press from './Press';
import Contact from './Contact';


import "./css/main.css"; 
import logo from './images/logo_inyo.png';



const customNavigation=()=>(
 
  
 
<Router>       

 <main>
 <header> 
  <div id="contenor"> 
    <div id="header">
    <div id="logo"><Link to="/" onClick={()=> window.location.href='/'} ><img src={logo} alt="INYO" /></Link></div>
    <div className="menu-icon"> <span></span> </div>
    <div id="navi">
            <ul>
            <li><Link to="/"> HOME </Link></li>
            <li className="has_sub"><Link to="/about"> ABOUT INYOC </Link> 
               <ul className="submenu">
                    <li><Link to="/artist-director"> Artist Director </Link></li>
                    <li><Link to="/managing-director"> Managing Director </Link></li>
               </ul>

            </li>
                <li><Link to="/project">PROJECTS</Link></li>
                <li><Link to="/videos">VIDEOS</Link></li>
                <li><Link to="/partner">PARTNERS</Link></li>
                <li><Link to="/press">PRESS</Link></li>
                <li><Link to="/contact">CONTACT</Link></li> 
        
            </ul>
      </div>  
     </div> 
      </div>
    </header> 

    
    <Route exact path='/' component={Home}></Route>
    
    <Route path='/about' component={About}></Route>
    <Route path='/artist-director' component={Artistdirector}></Route>
    <Route path='/managing-director' component={Managingdirector}></Route>
    <Route path='/project' component={Project}></Route>
    <Route path='/videos' component={Videos}></Route>
    <Route path='/partner' component={Partner}></Route>
    <Route path='/press' component={Press}></Route>
    <Route path='/contact' component={Contact}></Route>
    

    </main>
  

    </Router>

);


export default customNavigation;
