import React, { Component } from 'react';
import Footer from './Footer';
import $ from 'jquery';
import config from './config';

class Press extends Component {

    constructor(){
        super() 
          this.state = {
            data: []
          }
        
      }

      componentDidMount() {

        document.title = "Press -India Youth Orchestra";  
        document.getElementsByTagName("META")[2].content="India Youth Orchestra- Press";
        document.getElementsByTagName("META")[3].content="India Youth Orchestra,Press, News ";     
     

        $.ajax({
        url: config.get('apiUrl')+"/api/news",
        type: "GET",
        dataType: 'json',
        ContentType: 'application/json',
        success: function(data) {
           // console.log(data);
            this.setState({data: data});
        }.bind(this),
        error: function(jqXHR) {
            console.log(jqXHR);
        }.bind(this)
    })
    } 

// albumdetail = ()=> {
     
// }

    render() {
   
    $(window).scroll(function(){

        //this.alert('ddfdf');
        var sticky = $('header'),
            scroll = $(window).scrollTop();
        
        if (scroll >= 154) sticky.addClass('fixed');
        else sticky.removeClass('fixed');
        
        });
 

    return (
      <div id="wrapper">
    
    <div id="banner" className="contac">
<div className="heading2">List of Media Reports </div>

</div>

<div id="wrap">
    <div id="contenor">
    <div class="contentf2">
    <ul class="press_list">
    <li><b>Italian Opera – Cavalleria Rusticana</b>, in celebrating “150 years of Italy’s Unification” –  organized by the Embassy of Italy, Indian Council of Cultural Relations in collaboration with the Delhi International Arts Festival</li>
    {
this.state.data.map(function(item, key) {

    return (
   
<li><a href={item.newsurl} target="_blank">{item.title}</a> – [ <em>Source :</em> {item.sourcetitle} ]</li>

     );
    })  } 

  </ul>
	</div>


    </div>
</div>


    <Footer />


      </div>
    );

    
  }
}

export default Press;
