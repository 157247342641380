import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link
} from 'react-router-dom'

import Slider from "react-slick";
import "./css/main.css"; 
import logo from './images/logo_inyo.png';
import Footer from './Footer';
import $ from 'jquery';
import config from './config';



import arrowright from  './images/right_arrow.png';
import banner1 from './images/banners/banner1.jpg';
import mobileb1 from './images/banners/mobileb1.jpg';

class Home extends Component {
  constructor(){
    super() 
      this.state = {
        data: [],
        pagedata:[]
      }
  }
  
  

  componentDidMount() {

    document.title = "India National Youth Orchestra";  
    document.getElementsByTagName("META")[2].content="The India Youth Orchestra was created to give young Indian musicians the chance of developing their talents and being exposed to western orchestral repertoire.";
    document.getElementsByTagName("META")[3].content="India Youth Orchestra, Sonia Khan, Vijay Upadhyaya, Orchestra";     
    
    // alert(config.get('foo'));

    $.ajax({
    url: config.get('apiUrl')+"/api/banners",
    type: "GET",
    dataType: 'json',
    ContentType: 'application/json',
    success: function(data) {
       // console.log(data);
        this.setState({data: data});
    }.bind(this),
    error: function(jqXHR) {
        console.log(jqXHR);
    }.bind(this)
})

$.ajax({
  url: config.get('apiUrl')+"/api/getpagedetail?pageId=5",
  type: "GET",
  dataType: 'json',
  ContentType: 'application/json',
  success: function(pagedata) {
     // console.log(data);
      this.setState({pagedata: pagedata});
  }.bind(this),
  error: function(jqXHR) {
      console.log(jqXHR);
  }.bind(this)
})



} 
  

 

  
  render() {

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay:true
     
      
    };

  

    return (

 <div id="wrapper">


   

      <div className="flexslider">
        
        <Slider {...settings}>

    {
this.state.data.map(function(item, key) {
  return (  
        <li>
      <img src={item.bannerImageMobile} className="mobileimg" alt={item.title}/>
      <img src={item.bannerImage} className="desktopimg" alt={item.title} />
       </li>  

);
})  } 
      
   

        </Slider>
        </div>
        <div id="contenor">

 {
  this.state.pagedata.map(function(item2, key2) {
  return (  
<div>
<div className="heading1">{item2.pageHeadingText}
		<div className="h_bottom_line"></div>
</div>

<div className="contentf homAbout">
  <div className="innercontent">
         {item2.shortDescription}
  </div>

<a href='javascript:void(0)' className="red_btn btnarrow" onClick={()=> window.location.href='/about'}>READ MORE <img src={arrowright} alt="INYO" /></a>

</div>

</div>

);
})  } 


</div>

<Footer />

</div>
    
     
      
    );
  }
}

export default Home;
