import React, { Component } from "react";
import fblogo from './images/fb-icon.png';
import $ from 'jquery';
class Footer extends Component {
  
  componentDidMount() {
   // console.log('alim');
   $("loader").fadeOut();
   $("#preloader").delay(1000).fadeOut("slow");


   
 // mobile menu script 
 $('.menu-icon').click( function(e) {
  e.preventDefault();
  e.stopPropagation(); 
  $('#navi').toggleClass('active');
   // $('.ovrly').toggleClass('open');
  $(this).toggleClass('hover');
  });
  $('#navi').click( function(e) {
      e.stopPropagation(); 
  });
  $('body').click( function() {
      $('#navi').removeClass('active');
      $('.menu-icon').removeClass('hover');
    //$('.ovrly').removeClass('open');
  });

  var path = window.location.href; // because the 'href' property of the DOM element is the absolute path
  $('#navi li a').each(function() {
   if (this.href === path) {
    $(this).addClass('activelink');
   }
  });

  $(window).scroll(function() {
    if ($(this).scrollTop() > 700) {
        $('#toTop').fadeIn();
    } else {
        $('#toTop').fadeOut();
    }
});


$('#toTop').click(function(){
  
    $('html, body').animate({
        scrollTop: $('header').offset().top
    }, 1000);
 
  });
  
  
    }
  
  render() {
    return (
        <div>
          <div id="footer">
<div id="footerinner">
<div id="copyright">© 2018 India National Youth Orchestra & Chorus</div>
<div id="footerrighttxt">Join us on: &nbsp;&nbsp;
<a href="https://www.facebook.com/pages/India-National-Youth-Orchestra/122636914509342" target="_blank">
<img src={fblogo} alt="INYO" /></a></div>
</div>
</div>

<a href="javascript:void(0);" id="toTop" title="Back to top"  style={{display: 'none'}}><img src="http://res.cloudinary.com/dn3pusuy5/inyo/images/top1.png" /></a>

  <div id="preloader" >
      <div className="loader"><img src="http://res.cloudinary.com/dn3pusuy5/inyo/images/loading.gif" /></div>
    </div>

</div>
          
 
    );
  }
}
 
export default Footer;